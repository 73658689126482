import { fetchAnnouncements } from "@/features/announcements/announcementsAPI";
import { useCollaboratorsStore } from "@/features/collaborators/collaboratorsStore";
import { fetchPanelData } from "@/features/panel/panelAPI";
import { fetchTasks } from "@/features/tasks/taskAPI";
import { useProfileStore } from "@/features/user/profile/profileStore";
import { sanitizeTextForUrl } from "@/utils/utils";
import { useCallback, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";

import { useLocation, useNavigate } from "react-router-dom";

export const useClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) return;

      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};

export function useLocalStorage(key, initialValue) {
  // Initialize state with value from local storage or default
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  // Update local storage when state changes
  useEffect(() => {
    try {
      window.localStorage.setItem(key, JSON.stringify(storedValue));
    } catch (error) {
      console.error(error);
    }
  }, [key, storedValue]);

  return [storedValue, setStoredValue];
}

// Used to get the current panel id appended in the URL
export const useExtractPanelId = () => {
  const location = useLocation();
  const pathSegments = location.pathname
    ?.split("/")
    ?.filter((segment) => segment !== ""); // Split and filter out empty segments
  return pathSegments[pathSegments.length - 1]; // Return the last segment
};

export function useAccessibleClick(onClick) {
  const onKeyDown = useCallback(
    (e) => {
      if (e.key === "Enter" || e.key === " ") {
        e?.preventDefault();
        onClick();
      }
    },
    [onClick]
  );

  return onKeyDown;
}

export const useDebounce = (callback, delay) => {
  const debounceTimeout = useRef(null);

  const debounce = (value) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      callback(value);
    }, delay);
  };

  return debounce;
};

export const useHandleTaskClick = (
  activeDomain,
  panels,
  setActivePanel,
  activePanelTasks,
  setIsViewTaskOpen,
  setSelectedTask,
  setTaskLoading,
  setTaskError,
  setPanels,
  setTasks
) => {
  const navigate = useNavigate();

  const handleTaskClick = async (panelId, TaskID) => {
    const data = {
      domainID: activeDomain.id,
      panelID: panelId,
    };

    const curTaskPanel = panels.find((p) => p.id === panelId);

    if (!curTaskPanel) {
      toast.error("Panel no longer exists");
      return;
    }

    setActivePanel(curTaskPanel);

    if (activePanelTasks) {
      const curTask = activePanelTasks.find((task) => task.id === TaskID);

      if (curTask) {
        navigate(
          `/panel/${sanitizeTextForUrl(curTaskPanel.name)}/${curTaskPanel.id}`
        );
        setIsViewTaskOpen(true);
        setSelectedTask(curTask);
        return;
      } else {
        toast.error("Task no longer exists");
        return;
      }
    }

    navigate(
      `/panel/${sanitizeTextForUrl(curTaskPanel.name)}/${curTaskPanel.id}`
    );
    setTaskLoading(true);

    const tasksResponse = await fetchTasks(activeDomain.id, panelId);
    const panelMembersResponse = await fetchPanelData(data);

    if (!tasksResponse.success || !panelMembersResponse.success) {
      const errorMessage =
        tasksResponse.message ||
        panelMembersResponse.message ||
        "An error occurred";
      toast.error(errorMessage);
      setTaskError(tasksResponse.message);
      return;
    }

    const { panel_data, panel_members } = panelMembersResponse.data;
    const updatedPanels = panels.map((panel) =>
      panel.id === panel_data.id ? { ...panel, panel_members } : panel
    );

    setTaskLoading(false);
    setPanels(updatedPanels);
    setActivePanel(updatedPanels.find((p) => p.id === panel_data.id));
    setTasks(panelId, tasksResponse.data);

    const curTask = tasksResponse.data.find((task) => task.id === TaskID);

    if (!curTask) {
      toast.error("Task no longer exists");
      return;
    }

    setIsViewTaskOpen(true);
    setSelectedTask(curTask);
  };

  return handleTaskClick;
};

export const useHandleMentionClick = (
  announcements,
  setAnnouncementError,
  setAnnouncements,
  scrollToAnnouncement,
  activeDomain
) => {
  const navigate = useNavigate();

  const handleMentionClick = async (NotificationID) => {
    // Check if the announcement exists in the state
    const foundAnnouncement = announcements.find(
      (a) => a.id === NotificationID
    );

    if (!foundAnnouncement) {
      // Fetch announcements if not available
      const response = await fetchAnnouncements(activeDomain.id);

      if (!response.success) {
        const errorMessage = response.message || "An error occurred";
        toast.error(errorMessage);
        setAnnouncementError(response.message);
        return;
      }

      // Update announcements state with the fetched data
      setAnnouncements(response.data);

      // Re-check if the announcement now exists
      const updatedAnnouncement = response.data.find(
        (a) => a.id === NotificationID
      );

      if (!updatedAnnouncement) {
        toast.error("No announcement like this");
        return;
      }

      // Proceed if the announcement is found
      navigate(`/announcements?id=${NotificationID}`);
      scrollToAnnouncement(NotificationID);
      return;
    }

    // Proceed if the announcement is found
    navigate(`/announcements?id=${NotificationID}`);
    scrollToAnnouncement(NotificationID);
  };

  return handleMentionClick;
};

export function useIsPrivileged() {
  const collaborators = useCollaboratorsStore((store) => store.collaborators);
  const userData = useProfileStore((store) => store.userData);

  // Find the user logged in
  const isUser = collaborators?.find((c) => c.user?.id === userData?.id);

  const isPrivileged =
    isUser?.memberRole === "admin" || isUser?.memberRole === "owner";

  return { isPrivileged, isUser };
}

export const usePasswordCriteria = (
  initialPassword = "",
  initialConfirmPassword = ""
) => {
  const [password, setPassword] = useState(initialPassword);
  const [confirmPassword, setConfirmPassword] = useState(
    initialConfirmPassword
  );

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [criteriaVisible, setCriteriaVisible] = useState(false);

  const [criteria, setCriteria] = useState({
    length: false,
    number: false,
    specialChar: false,
    uppercase: false,
    match: false,
  });

  const checkCriteria = (password, confirmPassword) => {
    const isAnyPasswordNotEmpty = password !== "" || confirmPassword !== "";

    setCriteria({
      length: password.length >= 8,
      number: /\d/.test(password),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
      uppercase: /[A-Z]/.test(password),
      match: isAnyPasswordNotEmpty ? password === confirmPassword : false,
    });
  };

  return {
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    criteria,
    checkCriteria,
    showPassword,
    setShowPassword,
    showConfirmPassword,
    setShowConfirmPassword,
    criteriaVisible,
    setCriteriaVisible,
  };
};
