import { apiRequestWithTimeout, handleApiError } from "@/utils/apiUtils";
import { getSocket } from "@/sockets/socket";
export const addDomain = async (data) => {
  const config = {
    method: "post",
    url: "/domains",
    data,
  };

  const { data: responseData, error } = await apiRequestWithTimeout(config);

  if (error) return handleApiError(error);

  return { success: true, data: responseData };
};

export async function editDomain(data) {
  const { domainName, domainID } = data;

  const config = {
    method: "patch",
    url: `/domains/${domainID}`,
    data: { name: domainName },
  };

  const { data: responseData, error } = await apiRequestWithTimeout(config);

  if (error) return handleApiError(error);

  return { success: true, data: responseData };
}

export const deleteDomain = async (domainID) => {
  const config = {
    method: "delete",
    url: `/domains/${domainID}`,
  };

  const { data, error } = await apiRequestWithTimeout(config);

  if (error) return handleApiError(error);
  return { success: true, data };
};

export const leaveDomain = async (domainID) => {
  const socket = getSocket();
  const config = {
    method: "delete",
    url: `/domains/${domainID}/leave`,
  };

  const { data, error } = await apiRequestWithTimeout(config);

  socket.emit("send-collab-announcement", {
    domain: domainID,
    message: "User membership modified",
  });

  if (error) return handleApiError(error);
  return { success: true, data };
};

export const fetchDomains = async () => {
  const config = {
    method: "get",
    url: "/domains",
  };

  const { data, error } = await apiRequestWithTimeout(config);

  if (error) return handleApiError(error);

  return { success: true, data };
};

export const fetchDomainAnalytics = async (domainID) => {
  const config = {
    method: "get",
    url: `/analytics/${domainID}`,
  };

  const { data, error } = await apiRequestWithTimeout(config);

  if (error) return handleApiError(error);

  return { success: true, data };
};

export const fetchDomainAnalyticsAverageTime = async (domainID, range) => {
  const config = {
    method: "get",
    url: `/analytics/${domainID}/average-time?range=${range}`,
  };

  const { data, error } = await apiRequestWithTimeout(config);

  if (error) return handleApiError(error);

  return { success: true, data };
};

export const fetchDomainAnalyticsTotalTime = async (domainID, value) => {
  const config = {
    method: "get",
    url: `/analytics/${domainID}/total-time?range=${value}`,
  };

  const { data, error } = await apiRequestWithTimeout(config);

  if (error) return handleApiError(error);

  return { success: true, data };
};
