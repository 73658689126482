// External Libraries
import Logo from "@/UI/Logo";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export default function AuthLayout({ title, children, linkTo, linkText }) {
  return (
    <div
      className={`text-textColor font-plusjakarta flex items-center min-h-[100dvh] bg-veryDarkGray   p-4`}>
      <div className="w-full max-w-2xl mx-auto">
        <div className="flex justify-center p-3">
          <Logo className="!flex " />
        </div>
        <div className="flex flex-col justify-center px-6 py-4 pb-8 rounded-md sm:px-8 bg-darkGray">
          <h1 className="auth__header  mt-[10%]">{title}</h1>
          {children}

          {/*  */}
          <div className="text-[14px] mt-4  text-center sm:text-left">
            {`${linkText}`}{" "}
            <Link to={linkTo} className="font-semibold text-primary">
              {linkTo === "/auth/signup" ? ` Signup` : ` Login`}
            </Link>
          </div>
        </div>
        {/* <div className="flex flex-col justify-between gap-4 py-4 image bg-veryDarkGray ">
          <h2 className=" mt-[10%] font-medium leading-[160%] text-lg xl:text-xl px-4 lg:px-8">
            Experience effortlessly optimizing your work, connecting with team
            members, and transforming project management with Kreed
          </h2>
        </div> */}
      </div>
    </div>
  );
}

AuthLayout.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  linkTo: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
};
