import { create } from "zustand";
import { persist } from "zustand/middleware";

const initialSoundState = {
  appSound: true,
};
export const useSoundStore = create(
  persist(
    (set) => ({
      ...initialSoundState,
      setAppSound: (appSound) => set({ appSound }),

      reset: () => set(initialSoundState),
    }),
    { name: "sound-storage" }
  )
);

// Get state for notificationSound
export const getAppSound = () => {
  return useSoundStore.getState().appSound;
};
