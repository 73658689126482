export const TIMEOUT_DURATION = 25000; // 25 seconds
export const IMAGE_UPLOAD_TIMEOUT_DURATION = 60000; // 60 seconds

export const OAUTH_TIMEOUT_DURATION = 60000; // 60 seconds

export const MAX_TITLE_LENGTH = 100;

export const MAX_PROFILE_DETAILS_LENGTH = 50;

export const MAX_DESCRIPTION_LENGTH = 500;

export const MAX_FORM_INPUT_LENGTH = 35;

export const MAX_ANNOUNCEMENT_MESSAGE = 10000;

export const SIDEBAR_WIDTH = 350;

export const DEBOUNCE_DURATION = 250; // quarter of a second debounce delay for displaying results

export const SOCKET_MESSAGES = {
  taskActivities: "info", // Socket for task activities in panel

  tasksAssigned: "to-assigned", // Socket for tasks assigned to a user

  domainAnalytics: "to-assigned", // Socket for domain analytics

  panelCreation: "created-panel", // Socket for creating a panel

  panelModification: "panel-announcement", // Socket for editing or deleting a panel

  panelUserModification: "modify-announcement", // Socket for inviting or removing a user from a panel

  columnAnnouncement: "column-announcement", // Socket for creating or deleting a column

  generalAnnouncement: "gen-announcement", // Socket for creating or deleting an announcement

  mentionedInAnnouncement: "to-mentioned", // Socket for announcements you were mentioned in

  collaboratorUpdate: "collab-announcement", // Socket for updating collaborators

  collaboratorNotificationsUpdate: "update-role", // Socket for updating collaborators after a user's role is updated

  profileUpdate: "profile-update-announcement", // Socket for profile updates

  domainJoin: "joined-domain", // Socket for when a user joins a domain

  domainLeave: "removed-domain", // Socket for when a leaves or is removed from a domain

  domainModification: "domain-announcement", // Socket for when a domain name is edited
};

export const CHART_VALUES = [
  { key: "5D", value: "last5Days" },
  { key: "2W", value: "last2Weeks" },
  { key: "1M", value: "last1Month" },
  { key: "1.5M", value: "last6Weeks" },
  { key: "3M", value: "last3Months" },
];

export const FILTER_OPTIONS = [
  { value: "all", label: "All" },
  { value: "admins", label: "Admins only" },
  { value: "member", label: "Members only" },
];

export const SORT_OPTIONS = [
  { value: "desc", label: "Latest to oldest" },
  { value: "asc", label: "Oldest to latest" },
];

export const GRADIENTS = {
  1: "linear-gradient(135deg, #FFDAC2 0%, #FEF3EB 100%)",
  2: "linear-gradient(135deg, #C2D6FF 0%, #EBF1FF 100%)",
  3: "linear-gradient(135deg, #CAC2FF 0%, #EEEBFF 100%)",
};

export const CRITERIA_MESSAGE = {
  length: {
    met: "Your password contains at least 8 characters",
    unmet: "Your password should contain at least 8 characters",
  },
  number: {
    met: "Your password contains a number",
    unmet: "Your password should contain a number",
  },
  specialChar: {
    met: "Your password contains a special character",
    unmet: "Your password should contain a special character",
  },
  uppercase: {
    met: "Your password contains an uppercase letter",
    unmet: "Your password should contain an uppercase letter",
  },
  match: {
    met: "Your password does match the confirmation password",
    unmet: "Your password should match the confirmation password",
  },
};

export const COUNTRIES = [
  { name: "Afghanistan", id: "af" },
  { name: "Albania", id: "al" },
  { name: "Algeria", id: "dz" },
  { name: "Andorra", id: "ad" },
  { name: "Angola", id: "ao" },
  { name: "Antigua and Barbuda", id: "ag" },
  { name: "Argentina", id: "ar" },
  { name: "Armenia", id: "am" },
  { name: "Australia", id: "au" },
  { name: "Austria", id: "at" },
  { name: "Azerbaijan", id: "az" },
  { name: "Bahamas", id: "bs" },
  { name: "Bahrain", id: "bh" },
  { name: "Bangladesh", id: "bd" },
  { name: "Barbados", id: "bb" },
  { name: "Belarus", id: "by" },
  { name: "Belgium", id: "be" },
  { name: "Belize", id: "bz" },
  { name: "Benin", id: "bj" },
  { name: "Bhutan", id: "bt" },
  { name: "Bolivia", id: "bo" },
  { name: "Bosnia and Herzegovina", id: "ba" },
  { name: "Botswana", id: "bw" },
  { name: "Brazil", id: "br" },
  { name: "Brunei", id: "bn" },
  { name: "Bulgaria", id: "bg" },
  { name: "Burkina Faso", id: "bf" },
  { name: "Burundi", id: "bi" },
  { name: "Cabo Verde", id: "cv" },
  { name: "Cambodia", id: "kh" },
  { name: "Cameroon", id: "cm" },
  { name: "Canada", id: "ca" },
  { name: "Central African Republic", id: "cf" },
  { name: "Chad", id: "td" },
  { name: "Chile", id: "cl" },
  { name: "China", id: "cn" },
  { name: "Colombia", id: "co" },
  { name: "Comoros", id: "km" },
  { name: "Congo (Congo-Brazzaville)", id: "cg" },
  { name: "Costa Rica", id: "cr" },
  { name: "Croatia", id: "hr" },
  { name: "Cuba", id: "cu" },
  { name: "Cyprus", id: "cy" },
  { name: "Czechia (Czech Republic)", id: "cz" },
  { name: "Democratic Republic of the Congo", id: "cd" },
  { name: "Denmark", id: "dk" },
  { name: "Djibouti", id: "dj" },
  { name: "Dominica", id: "dm" },
  { name: "Dominican Republic", id: "do" },
  { name: "Ecuador", id: "ec" },
  { name: "Egypt", id: "eg" },
  { name: "El Salvador", id: "sv" },
  { name: "Equatorial Guinea", id: "gq" },
  { name: "Eritrea", id: "er" },
  { name: "Estonia", id: "ee" },
  { name: "Eswatini (fmr. 'Swaziland')", id: "sz" },
  { name: "Ethiopia", id: "et" },
  { name: "Fiji", id: "fj" },
  { name: "Finland", id: "fi" },
  { name: "France", id: "fr" },
  { name: "Gabon", id: "ga" },
  { name: "Gambia", id: "gm" },
  { name: "Georgia", id: "ge" },
  { name: "Germany", id: "de" },
  { name: "Ghana", id: "gh" },
  { name: "Greece", id: "gr" },
  { name: "Grenada", id: "gd" },
  { name: "Guatemala", id: "gt" },
  { name: "Guinea", id: "gn" },
  { name: "Guinea-Bissau", id: "gw" },
  { name: "Guyana", id: "gy" },
  { name: "Haiti", id: "ht" },
  { name: "Honduras", id: "hn" },
  { name: "Hungary", id: "hu" },
  { name: "Iceland", id: "is" },
  { name: "India", id: "in" },
  { name: "Indonesia", id: "id" },
  { name: "Iran", id: "ir" },
  { name: "Iraq", id: "iq" },
  { name: "Ireland", id: "ie" },
  { name: "Israel", id: "il" },
  { name: "Italy", id: "it" },
  { name: "Jamaica", id: "jm" },
  { name: "Japan", id: "jp" },
  { name: "Jordan", id: "jo" },
  { name: "Kazakhstan", id: "kz" },
  { name: "Kenya", id: "ke" },
  { name: "Kiribati", id: "ki" },
  { name: "Kuwait", id: "kw" },
  { name: "Kyrgyzstan", id: "kg" },
  { name: "Laos", id: "la" },
  { name: "Latvia", id: "lv" },
  { name: "Lebanon", id: "lb" },
  { name: "Lesotho", id: "ls" },
  { name: "Liberia", id: "lr" },
  { name: "Libya", id: "ly" },
  { name: "Liechtenstein", id: "li" },
  { name: "Lithuania", id: "lt" },
  { name: "Luxembourg", id: "lu" },
  { name: "Madagascar", id: "mg" },
  { name: "Malawi", id: "mw" },
  { name: "Malaysia", id: "my" },
  { name: "Maldives", id: "mv" },
  { name: "Mali", id: "ml" },
  { name: "Malta", id: "mt" },
  { name: "Marshall Islands", id: "mh" },
  { name: "Mauritania", id: "mr" },
  { name: "Mauritius", id: "mu" },
  { name: "Mexico", id: "mx" },
  { name: "Micronesia", id: "fm" },
  { name: "Moldova", id: "md" },
  { name: "Monaco", id: "mc" },
  { name: "Mongolia", id: "mn" },
  { name: "Montenegro", id: "me" },
  { name: "Morocco", id: "ma" },
  { name: "Mozambique", id: "mz" },
  { name: "Myanmar (formerly Burma)", id: "mm" },
  { name: "Namibia", id: "na" },
  { name: "Nauru", id: "nr" },
  { name: "Nepal", id: "np" },
  { name: "Netherlands", id: "nl" },
  { name: "New Zealand", id: "nz" },
  { name: "Nicaragua", id: "ni" },
  { name: "Niger", id: "ne" },
  { name: "Nigeria", id: "ng" },
  { name: "North Korea", id: "kp" },
  { name: "North Macedonia", id: "mk" },
  { name: "Norway", id: "no" },
  { name: "Oman", id: "om" },
  { name: "Pakistan", id: "pk" },
  { name: "Palau", id: "pw" },
  { name: "Palestine State", id: "ps" },
  { name: "Panama", id: "pa" },
  { name: "Papua New Guinea", id: "pg" },
  { name: "Paraguay", id: "py" },
  { name: "Peru", id: "pe" },
  { name: "Philippines", id: "ph" },
  { name: "Poland", id: "pl" },
  { name: "Portugal", id: "pt" },
  { name: "Qatar", id: "qa" },
  { name: "Romania", id: "ro" },
  { name: "Russia", id: "ru" },
  { name: "Rwanda", id: "rw" },
  { name: "Saint Kitts and Nevis", id: "kn" },
  { name: "Saint Lucia", id: "lc" },
  { name: "Saint Vincent and the Grenadines", id: "vc" },
  { name: "Samoa", id: "ws" },
  { name: "San Marino", id: "sm" },
  { name: "Sao Tome and Principe", id: "st" },
  { name: "Saudi Arabia", id: "sa" },
  { name: "Senegal", id: "sn" },
  { name: "Serbia", id: "rs" },
  { name: "Seychelles", id: "sc" },
  { name: "Sierra Leone", id: "sl" },
  { name: "Singapore", id: "sg" },
  { name: "Slovakia", id: "sk" },
  { name: "Slovenia", id: "si" },
  { name: "Solomon Islands", id: "sb" },
  { name: "Somalia", id: "so" },
  { name: "South Africa", id: "za" },
  { name: "South Korea", id: "kr" },
  { name: "South Sudan", id: "ss" },
  { name: "Spain", id: "es" },
  { name: "Sri Lanka", id: "lk" },
  { name: "Sudan", id: "sd" },
  { name: "Suriname", id: "sr" },
  { name: "Sweden", id: "se" },
  { name: "Switzerland", id: "ch" },
  { name: "Syria", id: "sy" },
  { name: "Taiwan", id: "tw" },
  { name: "Tajikistan", id: "tj" },
  { name: "Tanzania", id: "tz" },
  { name: "Thailand", id: "th" },
  { name: "Timor-Leste", id: "tl" },
  { name: "Togo", id: "tg" },
  { name: "Tonga", id: "to" },
  { name: "Trinidad and Tobago", id: "tt" },
  { name: "Tunisia", id: "tn" },
  { name: "Turkey", id: "tr" },
  { name: "Turkmenistan", id: "tm" },
  { name: "Tuvalu", id: "tv" },
  { name: "Uganda", id: "ug" },
  { name: "Ukraine", id: "ua" },
  { name: "United Arab Emirates", id: "ae" },
  { name: "United Kingdom", id: "gb" },
  { name: "United States of America", id: "us" },
  { name: "Uruguay", id: "uy" },
  { name: "Uzbekistan", id: "uz" },
  { name: "Vanuatu", id: "vu" },
  { name: "Venezuela", id: "ve" },
  { name: "Vietnam", id: "vn" },
  { name: "Yemen", id: "ye" },
  { name: "Zambia", id: "zm" },
  { name: "Zimbabwe", id: "zw" },
];

export const DEPARTMENTS = [
  { id: "admin", name: "Administration" },
  { id: "bizdev", name: "Business Development" },
  { id: "comm", name: "Communications" },
  { id: "compliance", name: "Compliance" },
  { id: "content", name: "Content" },
  { id: "cs", name: "Customer Success" },
  { id: "data", name: "Data Science" },
  { id: "design", name: "Design" },
  { id: "dev", name: "Development" },
  { id: "eng", name: "Engineering" },
  { id: "events", name: "Events" },
  { id: "facilities", name: "Facilities" },
  { id: "finance", name: "Finance" },
  { id: "gov", name: "Government Relations" },
  { id: "hr", name: "Human Resources" },
  { id: "it", name: "IT" },
  { id: "inv", name: "Investor Relations" },
  { id: "legal", name: "Legal" },
  { id: "logistics", name: "Logistics" },
  { id: "marketing", name: "Marketing" },
  { id: "ops", name: "Operations" },
  { id: "partnerships", name: "Partnerships" },
  { id: "pm", name: "Product Management" },
  { id: "pr", name: "Public Relations" },
  { id: "procurement", name: "Procurement" },
  { id: "qa", name: "Quality Assurance" },
  { id: "research", name: "Research" },
  { id: "sales", name: "Sales" },
  { id: "security", name: "Security" },
  { id: "strategy", name: "Strategy" },
  { id: "support", name: "Support" },
  { id: "supplychain", name: "Supply Chain" },
  { id: "training", name: "Training" },
  { id: "ux", name: "User Experience" },
  { id: "other", name: "Other" },
];

export const JOB_TITLES = [
  { id: "ae", name: "Account Executive" },
  { id: "ba", name: "Business Analyst" },
  { id: "bda", name: "Business Development Associate" },
  { id: "be", name: "Backend Engineer" },
  { id: "ceo", name: "Chief Executive Officer" },
  { id: "cfo", name: "Chief Financial Officer" },
  { id: "cio", name: "Chief Information Officer" },
  { id: "cm", name: "Content Manager" },
  { id: "cmo", name: "Chief Marketing Officer" },
  { id: "cso", name: "Chief Security Officer" },
  { id: "coo", name: "Chief Operating Officer" },
  { id: "cto", name: "Chief Technology Officer" },
  { id: "da", name: "Data Analyst" },
  { id: "dba", name: "Database Administrator" },
  { id: "de", name: "DevOps Engineer" },
  { id: "ds", name: "Data Scientist" },
  { id: "engMgr", name: "Engineering Manager" },
  { id: "fe", name: "Frontend Engineer" },
  { id: "fa", name: "Financial Analyst" },
  { id: "fs", name: "Full Stack Developer" },
  { id: "hrm", name: "Human Resources Manager" },
  { id: "intern", name: "Intern" },
  { id: "leadSe", name: "Lead Software Engineer" },
  { id: "pm", name: "Product Manager" },
  { id: "pmm", name: "Product Marketing Manager" },
  { id: "prodMgr", name: "Product Marketing Manager" },
  { id: "pr", name: "Public Relations" },
  { id: "qa", name: "QA Engineer" },
  { id: "sqa", name: "Senior QA Engineer" },
  { id: "sam", name: "Sales Account Manager" },
  { id: "se", name: "Software Engineer" },
  { id: "seo", name: "SEO Specialist" },
  { id: "sd", name: "Security Engineer" },
  { id: "sm", name: "Social Media Manager" },
  { id: "smc", name: "Scrum Master" },
  { id: "sse", name: "Senior Software Engineer" },
  { id: "sysAdmin", name: "System Administrator" },
  { id: "uid", name: "UI Designer" },
  { id: "uxd", name: "UX Designer" },
  { id: "other", name: "Other" },
];
