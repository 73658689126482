// Component imports

import Button from "@/UI/Button";
import { OnboardingPageWrapper } from "./Onboarding";

// External Libraries
import PropTypes from "prop-types";

export default function OnboardingHome({ onNext, onboardingState }) {
  return (
    <OnboardingPageWrapper
      className="flex flex-col items-center gap-2 "
      key={onboardingState}>
      <h2 className="flex flex-col items-center text-3xl font-bold ">
        <span>🎉</span>
        <span className="text-xl lg:text-5xl sm:text-3xl ">
          Welcome to Kreed
        </span>
      </h2>
      <p className="mb-4 text-xs text-center sm:text-base text-mediumGray">
        Set up your profile and get started today!
      </p>
      <Button
        content="Set up Profile"
        className="px-6 py-2 text-xs sm:px-8 sm:text-base w-max"
        onClick={onNext}
      />
    </OnboardingPageWrapper>
  );
}

OnboardingHome.propTypes = {
  onNext: PropTypes.func,
  onboardingState: PropTypes.number,
};
