import { getSocket } from "@/sockets/socket";
import { apiRequestWithTimeout, handleApiError } from "@/utils/apiUtils";

export async function sendAnnouncement(data) {
  const socket = getSocket();
  const { content, mentions, domainId, isAutomated } = data;

  const config = {
    method: "post",
    url: `/announcements/${domainId}`,
    data: {
      content,
      mentions,
      isAutomated,
    },
  };

  const { data: responseData, error } = await apiRequestWithTimeout(config);

  socket.emit("send-gen-announcement", {
    domain: domainId,
    message: "Sent Announcement",
  });

  socket.emit("send-to-mentioned", {
    domain: domainId,
    assignedUsers: mentions,
    message: "Sent Announcement with mentions",
  });

  if (error) return handleApiError(error);

  return { success: true, data: responseData };
}

export async function deleteAnnouncement({ domainID, id }) {
  const config = {
    method: "delete",
    url: `/announcements/${domainID}/${id}`,
  };

  const { data, error } = await apiRequestWithTimeout(config);
  const socket = getSocket();

  const socketData = {
    domain: domainID,
    message: "Deleted Announcement",
  };

  socket.emit("send-gen-announcement", socketData);

  if (error) return handleApiError(error);

  return { success: true, data };
}

export async function fetchAnnouncements(domainId) {
  const config = {
    method: "get",
    url: `/announcements/${domainId}`,
  };

  const { data, error } = await apiRequestWithTimeout(config);

  if (error) return handleApiError(error);

  return { success: true, data };
}
