// Component imports
import Avatar from "@/UI/Avatar";
import PanelIconMod from "@/UI/PanelIconMod";

// External Libraries
import { AnimatePresence, motion } from "framer-motion";
import PropTypes from "prop-types";

import { FaPlus } from "react-icons/fa";
import { FiHome } from "react-icons/fi";
import { IoIosNotificationsOutline } from "react-icons/io";
import { IoPeopleOutline } from "react-icons/io5";
import { PiHashBold } from "react-icons/pi";
import { Link, NavLink } from "react-router-dom";

// React imports
import { useRef, useState } from "react";

// Static imports
import KebabIcon from "@/assets/Kebab_menu.svg";

// API Hooks
import { fetchPanelData } from "../panel/panelAPI";
import { fetchTasks } from "../tasks/taskAPI";

// Custom Hooks
import { isNavLinkActive } from "@/utils/utils";

// Store Imports
import { useDomainStore } from "../domain/domainStore";
import { usePanelStore } from "../panel/panelStore";
import { useTaskStore } from "../tasks/tasksStore";

// Animation configs
import { useClickOutside, useIsPrivileged } from "@/hooks/hooks";
import { kebabContentVariants } from "@/utils/framerAnimationConfigs";
import { BiBookAlt } from "react-icons/bi";
import { FaXmark } from "react-icons/fa6";
import {
  useAnnouncementNotificationsStore,
  useTaskNotificationsStore,
} from "../notifications/notificationsStore";
import { useProfileStore } from "../user/profile/profileStore";

export default function HeaderKebabDropdown() {
  // Zustand States

  const { panels, setAddPanel } = usePanelStore((store) => store);

  const { loading: DomainLoading } = useDomainStore((store) => store);

  const [open, setOpen] = useState(false);

  const dropdownRef = useRef(null);

  const { isPrivileged } = useIsPrivileged();

  // Click outside handler
  useClickOutside(dropdownRef, () => setOpen(false));

  return (
    <div
      className="relative flex  items-center  justify-center min-[1201px]:hidden"
      ref={dropdownRef}>
      <button
        onClick={() => setOpen((prev) => !prev)}
        className="flex items-center justify-center w-8 p-2 rounded-full aspect-square sm:px-4 hover:bg-veryDarkGray">
        {open ? (
          <FaXmark className=" min-w-6" color="rgba(152, 170, 179, 1)" />
        ) : (
          <img className="block min-w-1" src={KebabIcon} alt="Kebab Icon" />
        )}
      </button>

      <AnimatePresence>
        {open && (
          <motion.ul
            className="grid border border-lines gap-1.5 p-2 rounded-md bg-veryDarkGray  absolute top-12 right-0 z-[100] min-w-44 w-max"
            initial={kebabContentVariants.initial}
            animate={kebabContentVariants.animate}
            exit={kebabContentVariants.exit}
            key={open}>
            <div className="flex flex-col pb-2 border-b-2 border-lines">
              <h2 className="p-2 text-[10px] tracking-widest font-bold uppercase text-textColor ">
                Panels ({panels.length})
              </h2>
              <div className="flex flex-col gap-1.5 ">
                {panels?.length >= 1 &&
                  panels?.map((panel) => {
                    const panelUrl = `${panel?.name
                      .toLowerCase()
                      .replace(/\s+/g, "-")
                      .replace(/\//g, "-")}`;
                    return (
                      <HeaderLink
                        key={panel.id}
                        to={`panel/${panelUrl}/${panel.id}`}
                        panel={panel}
                      />
                    );
                  })}
              </div>
              {isPrivileged && (
                <button
                  className="flex items-center gap-2 p-2 mt-2 rounded-md cursor-pointer text-mediumGray bg-primaryIdleHover hover:text-white"
                  disabled={DomainLoading}
                  onClick={() => {
                    setOpen(false);
                    setAddPanel(true);
                  }}>
                  <FaPlus size={12} />
                  <span className="text-[13px]">Add New Panel</span>
                </button>
              )}
            </div>

            {/* Render additional links */}
            <HeaderUtilityLinks setOpen={setOpen} />
          </motion.ul>
        )}
      </AnimatePresence>
    </div>
  );
}

function HeaderLink({ to, panel }) {
  // Zustand States
  const {
    setTasks,
    setLoading,
    loading,
    setError: setTaskError,
    tasks,
  } = useTaskStore((store) => store);

  const { panels, setPanels, setActivePanel } = usePanelStore((store) => store);

  const { activeDomain, loading: DomainLoading } = useDomainStore(
    (store) => store
  );

  const currentPanelId = to.split("/").pop();

  const handleClick = async (panel) => {
    setTaskError(null);
    setActivePanel(panel);

    const data = {
      domainID: activeDomain.id,
      panelID: currentPanelId,
    };

    if (tasks[currentPanelId]) {
      setTasks(currentPanelId, tasks[currentPanelId]);
      return;
    }

    setLoading(true);

    // Fetch tasks and panel data in parallel
    const [tasksResponse, panelMembersResponse] = await Promise.all([
      fetchTasks(activeDomain.id, currentPanelId),
      fetchPanelData(data),
    ]);

    if (!tasksResponse.success || !panelMembersResponse.success) {
      setTaskError(tasksResponse.message);
      setLoading(false);
      return;
    }

    // Update the state with the fetched data
    const { panel_data, panel_members } = panelMembersResponse.data;

    // Update the panels with its members
    const updatedPanels = panels.map((panel) => {
      if (panel.id === panel_data.id) {
        return {
          ...panel,
          panel_members: panel_members,
        };
      }
      return panel;
    });

    setPanels(updatedPanels);
    setActivePanel(updatedPanels.find((p) => p.id === panel_data.id));
    setTasks(currentPanelId, tasksResponse.data);
    setLoading(false);
  };

  return (
    <NavLink
      to={to}
      role="link"
      key={panel.id}
      onClick={() => handleClick(panel)}>
      <button
        className={`flex items-center gap-2 p-2 font-medium hover:bg-bgHover  hover:text-primary transition duration-300 text-[13px] w-full  rounded-md cursor-pointer text-mediumGray capitalize ${
          isNavLinkActive(panel.id) ? " bg-textColor" : ""
        }`}
        disabled={loading || DomainLoading}>
        <PanelIconMod width="14" height="15" />

        {panel.name}
      </button>
    </NavLink>
  );
}

HeaderLink.propTypes = {
  to: PropTypes.string.isRequired,
  panel: PropTypes.object.isRequired,
};

const HeaderUtilityLinks = ({ setOpen }) => {
  // Zustand States

  const announcementNotifications = useAnnouncementNotificationsStore(
    (store) => store.announcementNotifications
  );

  const taskNotifications = useTaskNotificationsStore(
    (store) => store.taskNotifications
  );

  // Zustand States
  const userData = useProfileStore((store) => store.userData);

  const { profilePicture } = userData || {};

  const unreadMentions = announcementNotifications?.filter((a) => !a.hasRead);

  const unreadTasks = taskNotifications?.filter((a) => !a.hasRead);

  return (
    <div className="flex flex-col gap-1 ">
      <Link
        to="home"
        onClick={() => setOpen(false)}
        className="block font-medium transition duration-300 rounded-md cursor-pointer hover:bg-darkGray">
        {" "}
        <div
          className={`flex p-2  hover:text-white items-center gap-2 text-mediumGray ${
            location.pathname === `/home` ? "text-textColor" : ""
          }`}>
          <FiHome size={20} />
          <p className="text-[13px]  capitalize">Home</p>
        </div>
      </Link>
      <Link
        to="profile"
        onClick={() => setOpen(false)}
        className="block font-medium transition duration-300 rounded-md cursor-pointer hover:bg-darkGray">
        {" "}
        <div
          className={`flex p-2  hover:text-white items-center gap-2 text-mediumGray ${
            location.pathname === `/profile` ? "text-textColor" : ""
          }`}>
          <Avatar width="1.25" imgSrc={profilePicture} />
          <p className="text-[13px]  capitalize">Account</p>
        </div>
      </Link>
      <Link
        to="notifications"
        onClick={() => setOpen(false)}
        className="block font-medium transition duration-300 rounded-md cursor-pointer hover:bg-darkGray">
        {" "}
        <div
          className={`flex p-2 relative  hover:text-white items-center gap-2 text-mediumGray ${
            location.pathname === `/notifications` ? "text-textColor" : ""
          }`}>
          <IoIosNotificationsOutline size={20} />
          <p className="text-[13px]  capitalize">Notifications</p>
          <span
            className={` flex items-center justify-center py-1 px-2 text-xs text-white ml-auto rounded-full ${
              unreadMentions?.length + unreadTasks?.length > 0
                ? "bg-[#EA5555]"
                : " bg-mediumGray"
            }  `}>
            {unreadMentions?.length + unreadTasks?.length || 0}
          </span>
        </div>
      </Link>
      <Link
        to="announcements"
        onClick={() => setOpen(false)}
        className="block font-medium transition duration-300 rounded-md cursor-pointer hover:bg-darkGray">
        {" "}
        <div
          className={`flex p-2  hover:text-white items-center gap-2 text-mediumGray ${
            location.pathname === `/announcements` ? "text-textColor" : ""
          }`}>
          <PiHashBold size={20} />
          <p className="text-[13px]  capitalize">Announcements</p>
        </div>
      </Link>
      <Link
        to="collaborators"
        onClick={() => setOpen(false)}
        className="block font-medium transition duration-300 rounded-md cursor-pointer hover:bg-darkGray">
        {" "}
        <div
          className={`flex p-2  hover:text-white items-center gap-2 text-mediumGray ${
            location.pathname === `/collaborators` ? "text-textColor" : ""
          }`}>
          <IoPeopleOutline size={20} />
          <p className="text-[13px]  capitalize">Collaborators</p>
        </div>
      </Link>
      <Link
        to="https://kreed-docs.vercel.app/"
        onClick={() => setOpen(false)}
        className="block font-medium transition duration-300 rounded-md cursor-pointer hover:bg-darkGray">
        {" "}
        <div
          className={`flex p-2  hover:text-white items-center gap-2 text-mediumGray `}>
          <BiBookAlt size={20} />
          <p className="text-[13px]  capitalize">Docs</p>
        </div>
      </Link>
    </div>
  );
};

HeaderUtilityLinks.propTypes = {
  setOpen: PropTypes.func.isRequired,
};
