import toast from "react-hot-toast";
// Hook to bind click event for mentions
import { useEffect } from "react";

// mentionUtils.js
export const formatMentions = (content, collaborators) => {
  // Create a mapping of userId to fullName for quick lookup
  const userIdToFullName = collaborators.reduce((acc, collab) => {
    acc[collab.user?.id] = collab.user.fullName;
    return acc;
  }, {});

  let formattedContent = content;

  // Check for @everyone and apply green color with capitalization
  formattedContent = formattedContent.replace(
    /@everyone/g,
    `<span style="color: #86d092; font-weight: bold; text-transform: capitalize;">@everyone</span>`
  );

  // Apply styles for other mentions
  Object.keys(userIdToFullName).forEach((userId) => {
    const fullName = userIdToFullName[userId];
    const mentionRegex = new RegExp(`@${fullName}`, "g");
    formattedContent = formattedContent.replace(
      mentionRegex,
      `<span style="color: #635fc7; font-weight: bold; cursor: pointer;" data-userid="${userId}" onmouseover="this.style.color='#a8a4ff'" onmouseout="this.style.color='#635fc7'">@${fullName}</span>`
    );
  });

  // Replace newline characters with <br> tags
  formattedContent = formattedContent.replace(/\n/g, "<br>");

  return formattedContent;
};

export const useMentionClickHandler = (
  collaborators,
  setProfileId,
  navigate
) => {
  useEffect(() => {
    // Define click handler
    const handleMentionClick = (e) => {
      const target = e.target;
      const userId = target.getAttribute("data-userid");
      if (userId) {
        const clickedCollaborator = collaborators.find(
          (c) => c.user?.id === userId
        );

        if (!clickedCollaborator) {
          toast.error("Collaborator not found");
          return;
        }

        setProfileId(userId);
        navigate("/collaborators");
      }
    };

    // Add global event listener
    document.addEventListener("click", handleMentionClick);

    // Cleanup the event listener
    return () => {
      document.removeEventListener("click", handleMentionClick);
    };
  }, [collaborators, setProfileId, navigate]);
};

export const handleCollaboratorClick = (
  collaborators,
  userId,
  setProfileId,
  navigate
) => {
  const clickedCollaborator = collaborators.find((c) => c.user?.id === userId);

  if (!clickedCollaborator) {
    toast.error("Collaborator not found");
    return;
  }

  setProfileId(userId);
  navigate("/collaborators");
};
