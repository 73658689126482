// Static imports
import DummyImage from "../assets/avatar.svg";

// External Libraries
import PropTypes from "prop-types";

export default function Avatar({ imgSrc, width, className }) {
  const style = {
    width: width ? `${width}rem` : "0.75rem",
  };
  return (
    <img
      src={imgSrc || DummyImage}
      alt="img"
      style={style}
      className={`${className} aspect-square rounded-full`}
    />
  );
}

Avatar.propTypes = {
  imgSrc: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.any,
};
