// Component imports
import Button from "@/UI/Button";

// API Hooks
import { addPanel, fetchPanelData } from "./panelAPI";

// Custom Hooks
import { isValidFormValue, sanitizeTextForUrl } from "@/utils/utils";

// External Libraries
import { FaXmark } from "react-icons/fa6";
import toast from "react-hot-toast";

// Store Imports
import { useDomainStore } from "../domain/domainStore";
import { useNavigate } from "react-router-dom";
import { usePanelStore } from "./panelStore";

// React imports
import { useState } from "react";
import { MAX_FORM_INPUT_LENGTH } from "@/utils/constants";
import { useProfileStore } from "../user/profile/profileStore";

export default function AddPanel() {
  // Zustand States
  const activeDomain = useDomainStore((store) => store.activeDomain);

  const userData = useProfileStore((store) => store.userData);

  const {
    setPanels,
    setAddPanel,
    loading: PanelLoading,
    setLoading,
    panels,
    setActivePanel,
  } = usePanelStore((store) => store);

  // React states and refs
  const [panelName, setPanelName] = useState("");

  const navigate = useNavigate();

  async function handleAddPanel(e) {
    e.preventDefault();

    if (!isValidFormValue(panelName)) {
      toast.error(
        "Panel name has to be longer than 2 letters excluding spaces"
      );
      return;
    }

    const sanitizedPanelName = sanitizeTextForUrl(panelName);
    const prevPanels = panels;

    // Optimistically update the state
    const newPanel = {
      id: Date.now(),
      name: panelName,
    };

    setPanels([...(Array.from(panels) || []), newPanel]);

    navigate(`/panel/${sanitizedPanelName}/${newPanel.id}`);
    setAddPanel(false);
    setLoading(true);

    const data = {
      panelName: panelName,
      domainID: activeDomain.id,
      authorID: userData.id,
    };

    const { success, data: addPanelResult } = await addPanel(data);

    if (!success) {
      navigate("/home");
      setPanels(prevPanels); // Revert to previous state if the API call fails
      console.error(addPanelResult.message);
      setLoading(false);
      return;
    }

    const actualPanelId = addPanelResult?.id;

    setActivePanel(addPanelResult);

    const panelMembersResponse = await fetchPanelData({
      domainID: activeDomain.id,
      panelID: actualPanelId,
    });

    if (!panelMembersResponse.success) {
      toast.error("Could not fetch your panel");
      setPanels(prevPanels); // Revert to previous state
      setLoading(false);
      navigate("/home");
      return;
    }

    const { panel_data, panel_members } = panelMembersResponse.data;

    // Update the URL to reflect the actual panel ID
    const updatedUrl = `/panel/${sanitizeTextForUrl(
      addPanelResult.name
    )}/${actualPanelId}`;

    navigate(updatedUrl);

    toast.success("Panel added successfully");

    const newUpdatedPanel = {
      ...addPanelResult, // Spread the existing properties
      panel_data, // Add the new panel_data property
      panel_members, // Add the new panel_members property
    };

    // Assuming prevPanels is an array
    const updatedPanels = [...prevPanels, newUpdatedPanel];
    setPanels(updatedPanels);

    setActivePanel(newUpdatedPanel);

    setPanelName("");

    setLoading(false);
  }

  const handleChange = (e) => {
    if (e.target.value.length <= MAX_FORM_INPUT_LENGTH)
      setPanelName(e.target.value);
  };

  return (
    <div>
      <div className="flex items-center justify-between ">
        <h2 className="mb-6 modal__header">Add New Panel</h2>
        <button
          className="self-start p-2 rounded-full cursor-pointer hover:bg-veryDarkGray"
          onClick={() => setAddPanel(false)}>
          <FaXmark size={20} />
        </button>
      </div>
      <form className="grid gap-8" onSubmit={handleAddPanel}>
        <div className="relative ">
          <label htmlFor="name" className="block modal__labels">
            Panel Name
          </label>
          <input
            type="text"
            name="Panel name"
            id="Panel name"
            onChange={handleChange}
            value={panelName}
            placeholder="Frontend Activities"
            className=" input__style__modal"
          />
          <p className="absolute top-0 text-sm font-light right-1 text-mediumGray">
            {panelName.length} / {MAX_FORM_INPUT_LENGTH}
          </p>
        </div>
        {panels?.length < 5 && (
          <Button content="Create New Panel" disabled={PanelLoading} />
        )}
      </form>
    </div>
  );
}
