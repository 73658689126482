// Component imports
import Button from "@/UI/Button";
import Dropdown from "@/UI/DropdownPicker";
import TimePicker from "@/UI/TimePicker";
import { OnboardingPageWrapper } from "./Onboarding";

// External Libraries
import PropTypes from "prop-types";

// Constants
import { COUNTRIES } from "@/utils/constants";

// React imports

// Custom Hooks
import { convertFromISODateTime } from "@/utils/dateAndTimeUtils";

// Context
import { useOnboardingContext } from "./OnboardingContext";

export default function OnboardingPreferences({
  onBack,
  onNext,
  onboardingState,
}) {
  // Context value extraction
  const {
    handleChange,
    selectedLocation,
    setSelectedLocation,
    defaultFromTime,
    setDefaultFromTime,
    defaultToTime,
    setDefaultToTime,
  } = useOnboardingContext() || {};

  const handleTimeChange = (type, value) => {
    if (type === "from") {
      setDefaultFromTime(value);
      handleChange("availableHoursFrom", value);
    }
    if (type === "to") {
      setDefaultToTime(value);
      handleChange("availableHoursTo", value);
    }
  };

  return (
    <OnboardingPageWrapper
      className="flex flex-col gap-8"
      key={onboardingState}>
      <div>
        <h2 className="text-xl text-bold">Preferences</h2>
        <p className="mb-4 text-mediumGray">Specify your Preferences Below</p>
      </div>
      <div className="flex flex-col items-center gap-4 md:flex-row">
        <Dropdown
          selectedItem={selectedLocation}
          setSelectedItemAction={(country) => {
            setSelectedLocation(country);
            handleChange("location", country.name);
          }}
          items={COUNTRIES}
          label="Location">
          <Dropdown.Header />
          <Dropdown.Button />
          <Dropdown.List
            direction="bottom"
            className="!bg-darkGray"
            searchClass=" !bg-darkGray"
          />
        </Dropdown>
      </div>

      {/* Time picker */}
      <div className="flex flex-col">
        <label htmlFor="availableHours" className="block mb-2 text-sm">
          Available Hours
        </label>
        <TimePicker
          fromTime={convertFromISODateTime(defaultFromTime)}
          toTime={convertFromISODateTime(defaultToTime)}
          onChange={handleTimeChange}>
          <TimePicker.TimeSelector type="from" />
          <TimePicker.TimeSelector type="to" />
        </TimePicker>
      </div>
      <div className="flex gap-4">
        <Button
          variant="secondary"
          content="Back"
          className="px-8 py-2 w-max"
          onClick={onBack}
        />
        <Button
          content="Next"
          className="px-8 py-2 rounded-full w-max"
          onClick={onNext}
        />
      </div>
    </OnboardingPageWrapper>
  );
}

OnboardingPreferences.propTypes = {
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  onboardingState: PropTypes.number,
};
