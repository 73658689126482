//// Component imports
import Button from "@/UI/Button";
import { OnboardingPageWrapper } from "./Onboarding";

// Static imports
import DocsImage from "@/assets/docs.svg";

// External Libraries
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export default function OnboardingDocs({ onBack, onNext, onboardingState }) {
  return (
    <OnboardingPageWrapper key={onboardingState}>
      <div className="flex flex-col items-center justify-center ">
        <div className="flex flex-col ">
          <div className="flex flex-col  justify-between pt-3 px-3 border rounded-lg h-[225px] border-lines hover:border-primary cursor-pointer transition-all duration-300">
            <h3 className="font-bold ">Read Docs</h3>
            <Link to="https://kreed-docs.vercel.app/" target="_blank">
              <img src={DocsImage} className=" w-fit" alt="" />
            </Link>
          </div>
          <div className="flex gap-4 mt-6">
            <Button
              variant="secondary"
              content="Back"
              className="px-8 py-2 w-max"
              onClick={onBack}
            />
            <Button
              content="Skip"
              className="px-8 py-2 rounded-full w-max"
              onClick={onNext}
            />
          </div>
        </div>
      </div>
    </OnboardingPageWrapper>
  );
}

OnboardingDocs.propTypes = {
  onBack: PropTypes.func,
  onNext: PropTypes.func,
  onboardingState: PropTypes.number,
};
