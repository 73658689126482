// External Libraries
import PropTypes from "prop-types";
import { FaEye } from "react-icons/fa6";
import { IoPeopleOutline } from "react-icons/io5";
import { PiHashBold } from "react-icons/pi";
import { NavLink, useLocation } from "react-router-dom";

// Component imports
import SidebarPanelContainer from "./SidebarPanelContainer";
import SidebarUtilis from "./SidebarUtilis";

// API Hooks

// Store Imports

import { usePanelStore } from "../panel/panelStore";

// Custom Hooks
import { useDynamicWidth } from "@/context/useDynamicWidth";
import { useAccessibleClick } from "@/hooks/hooks";
import { SIDEBAR_WIDTH } from "@/utils/constants";
import { BiBookAlt } from "react-icons/bi";

export default function Sidebar() {
  // Zustand States
  const panels = usePanelStore((store) => store.panels);

  const location = useLocation();

  // Context
  const { dynamicWidth, setDynamicWidth } = useDynamicWidth() || {};

  const isActive = (path) => location.pathname === path;

  const handleToggleSidebar = () => setDynamicWidth(SIDEBAR_WIDTH);

  const handleKeyDown = useAccessibleClick(() =>
    setDynamicWidth(SIDEBAR_WIDTH)
  );

  return (
    <>
      <div
        className={` hidden pb-3 transition-all duration-300 ease-in-out sm:flex bg-darkGray  sidebar text-mediumGray `}
        style={{
          width: `${dynamicWidth}px`,
        }}>
        <div
          className={`${dynamicWidth === 0 && "!hidden"} flex-col flex w-full`}>
          <div className="border-b border-lines">
            {" "}
            <SectionHeader title="Panels" count={panels?.length || 0} />
          </div>

          <SidebarPanelContainer />

          <NavLink
            to="/announcements"
            aria-label="Announcements"
            className="pb-2 mb-2 border-b border-lines small__heading ">
            {/*  */}

            <SectionHeader title="Channels" count={1} />

            {/*  */}
            <SidebarListItem
              Icon={PiHashBold}
              text="Announcements"
              isActive={isActive("/announcements")}
            />
          </NavLink>

          <NavLink
            to="/collaborators"
            aria-label="Collaborators"
            className="mt-2 sidebar__item small__heading">
            <SidebarListItem
              Icon={IoPeopleOutline}
              text="Collaborators"
              isActive={isActive("/collaborators")}
            />
          </NavLink>
          <NavLink
            to="https://kreed-docs.vercel.app/"
            aria-label="Docs"
            target="_blank"
            className="mt-2 sidebar__item small__heading">
            <SidebarListItem Icon={BiBookAlt} text="Docs" />
          </NavLink>

          <SidebarUtilis setDynamicWidth={setDynamicWidth} />
        </div>
      </div>
      {dynamicWidth === 0 && (
        <div
          className="absolute top-[85dvh] left-0 mr-3 px-4 py-2 rounded-tr-full rounded-br-full bg-primary z-20 cursor-pointer hover:bg-primaryHover hidden sm:flex"
          aria-label="Hide Sidebar"
          onClick={handleToggleSidebar}
          onKeyDown={handleKeyDown}>
          <FaEye color="#fff" />
        </div>
      )}
    </>
  );
}

function SectionHeader({ title, count }) {
  return (
    <p className="px-3 my-[17.5px] tracking-widest uppercase smaller__text lg:px-7">
      {title} ({count})
    </p>
  );
}

function SidebarListItem({ Icon, text, isActive, onClick, className }) {
  const activeClass = isActive
    ? "bg-primary  text-white"
    : "hover:bg-bgHover hover:text-primary";

  return (
    <div
      className={`flex items-center gap-4 py-[12px] mr-3 transition-all duration-300 text-[14px] cursor-pointer rounded-tr-3xl font-semibold tracking-wide  rounded-br-3xl px-3  lg:px-7 ${activeClass} ${className}`}
      onClick={() => onClick?.()}>
      {typeof Icon === "string" ? (
        <img className="w-4 h-4" src={Icon} alt={`${text} Icon`} />
      ) : (
        <Icon size={20} color={isActive ? "fff" : "#828FA3"} />
      )}
      <span>{text}</span>
    </div>
  );
}

SidebarListItem.propTypes = {
  Icon: PropTypes.any,
  text: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};
SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
};
