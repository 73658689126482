// Static Imports
import PropTypes from "prop-types";
import KreedLogo from "/kreed-logo.svg";
import { Link } from "react-router-dom";

export default function Logo({ className }) {
  return (
    <Link
      to="home"
      className={`items-center hidden border-lines gap-4 logo pr-7 sm:flex ${className} `}>
      <img
        className="w-6 lg:w-8 aspect-square"
        src={KreedLogo}
        alt="Kreed Logo"
      />

      <span
        className={`hidden text-2xl font-bold lg:text-3xl text-textColor sm:block ${className} `}>
        Kreed
      </span>
    </Link>
  );
}

Logo.propTypes = {
  className: PropTypes.string,
};
