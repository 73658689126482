// Component imports
import BarLoader from "@/UI/BarLoader";
import Button from "@/UI/Button";
import { OnboardingPageWrapper } from "./Onboarding";

// Static imports
import FullImage from "@/assets/full_video.svg";

// External Libraries
import PropTypes from "prop-types";

// Context
import { useOnboardingContext } from "./OnboardingContext";
import { Link } from "react-router-dom";

export default function OnboardingFull({ onBack, onboardingState }) {
  const { loading, handleUserUpdate } = useOnboardingContext();
  return (
    <OnboardingPageWrapper key={onboardingState}>
      <div>
        <div className="flex flex-col w-full ">
          <div className="flex   flex-col  justify-between   min-h-[225px]  w-full  cursor-pointer transition-all duration-300  gap-8 ">
            <div className="flex flex-col self-center gap-8 p-4 pb-0 ">
              <Link
                to="https://www.youtube.com/watch?v=YzdcpTlGtdA&t=1s"
                target="_blank"
                className="relative w-[300px] md:w-[600px] min-h-[200px] flex flex-col gap-4 p-4 pb-0 border rounded-lg border-lines ">
                <h3 className="font-bold ">Watch Full Video</h3>
                <img src={FullImage} className=" w-fit" alt="" />
              </Link>
              <div className="flex gap-4 ">
                <Button
                  variant="secondary"
                  content="Back"
                  className="px-8 py-2 w-max"
                  onClick={onBack}
                  disabled={loading}
                />
                <Button
                  content={loading ? <BarLoader /> : "Finish"}
                  className="px-8 py-2 rounded-full w-max"
                  disabled={loading}
                  onClick={handleUserUpdate}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </OnboardingPageWrapper>
  );
}

OnboardingFull.propTypes = {
  onBack: PropTypes.func,
  onboardingState: PropTypes.number,
};
