// Component imports
import Onboarding from "@/features/onboarding/Onboarding";
import AddPanel from "@/features/panel/AddPanel";
import Sidebar from "@/features/sidebar/Sidebar";
import Header from "../features/header/Header";
import BarLoader from "./BarLoader";
import ModalWrapper from "./ModalWrapper";
import SessionExpire from "./SessionExpire";

// External Libraries
import { Outlet, useLocation, useSearchParams } from "react-router-dom";

// React impports
import { Suspense, useEffect, useState } from "react";

// Context
import { DynamicWidthProvider } from "@/context/DynamicWidthProvider";
import { OnboardingProvider } from "@/features/onboarding/OnboardingContext";

// API Hooks
import { getUserProfile } from "@/features/user/profileAPI";

// Store Imports
import { useAnalyticsStore } from "@/features/analytics/analyticsStore";
import { fetchAnnouncements } from "@/features/announcements/announcementsAPI";
import { AnnouncementProvider } from "@/features/announcements/AnnouncementsContext";
import { useAnnouncementsStore } from "@/features/announcements/announcementsStore";
import { fetchCollaborators } from "@/features/collaborators/collaboratorsAPI";
import { useCollaboratorsStore } from "@/features/collaborators/collaboratorsStore";
import { fetchStatuses } from "@/features/column/columnAPI";
import { useColumnStore } from "@/features/column/columnStore";
import {
  fetchDomainAnalytics,
  fetchDomains,
} from "@/features/domain/domainAPI";
import { useDomainStore } from "@/features/domain/domainStore";
import {
  fetchAnnouncementNotifications,
  fetchTaskNotifications,
} from "@/features/notifications/notificationsAPI";
import {
  useAnnouncementNotificationsStore,
  useTaskNotificationsStore,
} from "@/features/notifications/notificationsStore";
import { useOnboardingStore } from "@/features/onboarding/onBoardingStore";
import { fetchPanelData, fetchPanels } from "@/features/panel/panelAPI";
import { usePanelStore } from "@/features/panel/panelStore";
import { fetchTasks } from "@/features/tasks/taskAPI";
import { useTaskStore } from "@/features/tasks/tasksStore";
import { useAvatarStore } from "@/features/user/profile/avatarStore";
import { useProfileStore } from "@/features/user/profile/profileStore";
import { useUserSettingsStore } from "@/features/user/userSettingsStore";
import {
  getSocket,
  initializeSocket,
  useSocketListener,
} from "@/sockets/socket";
import { SOCKET_MESSAGES } from "@/utils/constants";
import { useSessionStore } from "./sessionsStore";
import UtilitySearch from "./UtilitySearch";

export default function AppLayout() {
  const socket = getSocket();
  // Zustand States
  const isOnboarding = useOnboardingStore((store) => store.isOnboarding);

  const { setShowAvatar, setControls } = useAvatarStore((state) => state);

  const { setEditProfile, setEditAccount } = useUserSettingsStore(
    (store) => store
  );

  const { isAddPanelOpen, setAddPanel, setPanels } = usePanelStore(
    (store) => store
  );

  const setCollaborators = useCollaboratorsStore(
    (store) => store.setCollaborators
  );

  const userData = useProfileStore((store) => store.userData);

  const { activeDomain, setActiveDomain } = useDomainStore((store) => store);

  const sessionExpired = useSessionStore((store) => store.sessionExpired);

  const [searchParams] = useSearchParams();
  const searchTerm = searchParams.get("option");

  const [showUtilitySearch, setShowUtilitySearch] = useState(false);

  const setTasks = useTaskStore((store) => store.setTasks);

  const setTaskNotifications = useTaskNotificationsStore(
    (store) => store.setTaskNotifications
  );

  const setAnnouncements = useAnnouncementsStore(
    (store) => store.setAnnouncements
  );

  const setDomains = useDomainStore((store) => store.setDomains);

  const setAnalytics = useAnalyticsStore((store) => store.setAnalytics);

  const setColumns = useColumnStore((store) => store.setColumns);

  const setAnnouncementNotifications = useAnnouncementNotificationsStore(
    (store) => store.setAnnouncementNotifications
  );

  const location = useLocation();

  // Extracted panel ID from URL
  // const extractedPanelId = useExtractPanelId();
  // Closs the avatar component when you use the browser back button
  useEffect(() => {
    if (!location.pathname.includes("/profile") || searchTerm !== "") {
      setShowAvatar(false);
      setControls(false);
      setEditProfile(false);
    }
  }, [
    location.pathname,
    searchTerm,
    setControls,
    setEditProfile,
    setShowAvatar,
  ]);

  // Closs the avatar component when you use the browser's back button

  useEffect(() => {
    if (!location.pathname.includes("/account") || searchTerm !== "") {
      setEditAccount(false);
    }
  }, [location.pathname, searchTerm, setEditAccount]);

  // To check if token has expired and logout user (Will change to a websocket later)
  useEffect(() => {
    async function fetchData() {
      // You can await here
      await getUserProfile();

      // ...
    }
    fetchData();
  }, []);

  // Initialize Socket on every reload

  useEffect(() => {
    const params = {
      userId: userData?.id,
      domainId: activeDomain.id,
    };
    initializeSocket(params);
  }, [userData, activeDomain]);

  // Socket for task activities in panel
  useSocketListener(
    SOCKET_MESSAGES.taskActivities, // "info",
    (panelID) => fetchTasks(activeDomain.id, panelID), // Fetch tasks using panelID if available
    (panelID, data) => setTasks(panelID, data), // Store data with panelID
    [activeDomain.id, setTasks, socket],
    true // This flag ensures panelID extraction is checked
  );

  useSocketListener(
    SOCKET_MESSAGES.taskActivities,
    () => fetchDomainAnalytics(activeDomain.id),
    setAnalytics,
    [activeDomain.id, setAnalytics, socket]
  );

  // Socket for tasks assigned to
  useSocketListener(
    SOCKET_MESSAGES.tasksAssigned,
    () => fetchTaskNotifications(activeDomain.id),
    setTaskNotifications,
    [activeDomain.id, setTaskNotifications, socket]
  );

  useSocketListener(
    SOCKET_MESSAGES.tasksAssigned,
    () => fetchDomainAnalytics(activeDomain.id),
    setAnalytics,
    [activeDomain.id, setAnalytics, socket]
  );

  // Socket for creating a panel
  useSocketListener(
    SOCKET_MESSAGES.panelCreation,
    () => fetchPanels(activeDomain.id),
    setPanels,
    [activeDomain.id, setPanels, socket]
  );

  // Socket for editing or deleting a panel you're part of
  useSocketListener(
    SOCKET_MESSAGES.panelModification,
    () => fetchPanels(activeDomain.id),
    setPanels,
    [activeDomain.id, setPanels, socket]
  );

  // Socket for creating, editing or deleting a column
  useSocketListener(
    SOCKET_MESSAGES.columnAnnouncement,
    () => fetchStatuses(activeDomain.id),
    setColumns,
    [activeDomain.id, setColumns, socket]
  );

  // Socket for creating or deleting an announcement
  useSocketListener(
    SOCKET_MESSAGES.generalAnnouncement,
    () => fetchAnnouncements(activeDomain.id),
    setAnnouncements,
    [activeDomain.id, setAnnouncements, socket]
  );

  // Socket for seeing announcements you were mentioned in
  useSocketListener(
    SOCKET_MESSAGES.mentionedInAnnouncement,
    () => fetchAnnouncementNotifications(activeDomain.id),
    setAnnouncementNotifications,
    [activeDomain.id, setAnnouncementNotifications, socket]
  );

  // Socket for updating collaborators based on actions that affect them
  useSocketListener(
    SOCKET_MESSAGES.collaboratorUpdate,
    () => fetchCollaborators(activeDomain.id),
    setCollaborators,
    [activeDomain.id, setCollaborators, socket]
  );

  // Socket for when a user updates their profile
  useSocketListener(
    SOCKET_MESSAGES.profileUpdate,
    () => fetchCollaborators(activeDomain.id),
    setCollaborators,
    [activeDomain.id, setCollaborators, socket]
  );

  // Socket for getting the most recent users after someone joins a domain
  useSocketListener(
    SOCKET_MESSAGES.domainJoin,
    () => fetchCollaborators(activeDomain.id),
    setCollaborators,
    [activeDomain.id, setCollaborators, socket]
  );

  // Socket for getting the most recent announcements after someone's role is updated
  useSocketListener(
    SOCKET_MESSAGES.collaboratorNotificationsUpdate,
    () => fetchAnnouncements(activeDomain.id),
    setAnnouncements,
    [activeDomain.id, setAnnouncements, socket]
  );

  // Socket for getting the most recent announcements after someone joins a domain
  useSocketListener(
    SOCKET_MESSAGES.domainJoin,
    () => fetchAnnouncements(activeDomain.id),
    setAnnouncements,
    [activeDomain.id, setAnnouncements, socket]
  );
  // Socket for getting the most recent announcements after someone leaves a domain
  useSocketListener(
    SOCKET_MESSAGES.domainLeave,
    () => fetchAnnouncements(activeDomain.id),
    setAnnouncements,
    [activeDomain.id, setAnnouncements, socket]
  );

  // Socket for getting the most recent domain information after a user the domain name has be edited
  useEffect(() => {
    const handleSocketMessage = async () => {
      // Recursive function to retry fetching domains
      const attemptFetch = async (retryCount = 0) => {
        const result = await fetchDomains();

        if (!result.success) {
          console.error(`Attempt ${retryCount + 1}: fetchDomains failed.`);

          // Stop if maxRetries is reached
          if (retryCount >= 5) {
            console.error(
              "Max retries reached for fetchDomains. Stopping attempts."
            );
            return;
          }

          // Retry after a delay if the fetch fails
          setTimeout(() => {
            attemptFetch(retryCount + 1); // Increment retry count
          }, 2000); // Retry delay of 2000 milliseconds
          return;
        }

        const newActiveDomain = result.data.find(
          (d) => d.id === activeDomain.id
        );
        setActiveDomain(newActiveDomain);
        setDomains(result.data);
      };

      // Initial fetch attempt
      attemptFetch();
    };

    socket?.on(SOCKET_MESSAGES.domainModification, handleSocketMessage);

    // Clean up the socket listener
    return () => {
      socket?.off(SOCKET_MESSAGES.domainModification, handleSocketMessage);
    };
  }, [activeDomain.id, setActiveDomain, setDomains, socket]);

  // Socket for getting the most recent panel information after a user has been invited or removed
  useEffect(() => {
    const handleSocketMessage = async (message) => {
      let panelID = null;
      const match = message.match(/\[(.*?)\]/); // Extract panelID from message
      panelID = match ? match[1] : null;

      // Recursive function to fetch panels with retry logic
      const attemptFetchPanels = async (retryCount = 0) => {
        const panelResult = await fetchPanels(activeDomain.id);

        if (!panelResult.success) {
          console.error(`Attempt ${retryCount + 1}: fetchPanels failed.`);

          // Stop if maxRetries is reached
          if (retryCount >= 5) {
            console.error(
              "Max retries reached for fetchPanels. Stopping attempts."
            );
            return null;
          }

          // Retry after a delay if the fetch fails
          setTimeout(() => {
            attemptFetchPanels(retryCount + 1); // Increment retry count
          }, 2000); // Retry delay of 2000 milliseconds
          return null;
        }

        setPanels(panelResult.data); // Store the panels
        return panelResult.data; // Return the fetched panels
      };

      // Recursive function to fetch panel data with retry logic
      const attemptFetchPanelData = async (panelID, retryCount = 0) => {
        const data = {
          domainID: activeDomain.id,
          panelID: panelID,
        };

        const panelDataResult = await fetchPanelData(data);

        if (!panelDataResult.success) {
          console.error(`Attempt ${retryCount + 1}: fetchPanelData failed.`);

          // Stop if maxRetries is reached
          if (retryCount >= 5) {
            console.error(
              "Max retries reached for fetchPanelData. Stopping attempts."
            );
            return null;
          }

          // Retry after a delay if the fetch fails
          setTimeout(() => {
            attemptFetchPanelData(panelID, retryCount + 1); // Increment retry count
          }, 2000); // Retry delay of 2000 milliseconds
          return null;
        }

        return panelDataResult.data; // Return the fetched panel data
      };

      // Initial fetch attempt for panels
      const fetchedPanels = await attemptFetchPanels();
      if (!fetchedPanels) return; // Exit if fetching panels failed

      // Step 2: Fetch data for the specific panelID if it exists
      if (panelID) {
        const panelData = await attemptFetchPanelData(panelID);
        if (!panelData) return; // Exit if fetching panel data failed

        const { panel_data, panel_members } = panelData;

        // Update the panels with its members
        const updatedPanels = fetchedPanels.map((panel) => {
          if (panel.id === panel_data.id) {
            return {
              ...panel,
              panel_members, // Add the updated members to the panel
            };
          }
          return panel;
        });

        setPanels(updatedPanels); // Store the updated panels with members
      }
    };

    socket?.on(SOCKET_MESSAGES.panelUserModification, handleSocketMessage);

    // Clean up the socket listener
    return () => {
      socket?.off(SOCKET_MESSAGES.panelUserModification, handleSocketMessage);
    };
  }, [activeDomain.id, setPanels, socket]);

  return (
    <>
      <div className={`w-full relative bg-veryDarkGray `}>
        <div className={` font-plusjakarta text-textColor relative  `}>
          <Header setShowUtilitySearch={setShowUtilitySearch} />
          <div className="flex h-full app__layout">
            <DynamicWidthProvider>
              <Sidebar />
            </DynamicWidthProvider>

            <main className="relative flex w-full overflow-x-auto   border-r-[1px] border-l-[1px] border-lines xl:justify-center main ">
              <div className="max-w-[1500px] relative  w-full ">
                <Suspense
                  fallback={
                    <div className="h-[100dvh] flex justify-center items-center ">
                      <BarLoader />
                    </div>
                  }>
                  <Outlet />
                </Suspense>
                <ModalWrapper isOpen={isAddPanelOpen} onClose={setAddPanel}>
                  <AddPanel />
                </ModalWrapper>
              </div>
            </main>
          </div>
          <AnnouncementProvider>
            {showUtilitySearch && (
              <UtilitySearch
                showUtilitySearch={showUtilitySearch}
                setShowUtilitySearch={setShowUtilitySearch}
              />
            )}
          </AnnouncementProvider>
        </div>

        <ModalWrapper isOpen={sessionExpired}>
          <SessionExpire />
        </ModalWrapper>
      </div>
      <OnboardingProvider>
        {/* <Onboarding /> */}
        {isOnboarding && <Onboarding />}
      </OnboardingProvider>
    </>
  );
}
