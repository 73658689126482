// Constants
import { COUNTRIES, DEPARTMENTS, JOB_TITLES } from "@/utils/constants";

// React imports
import { createContext, useContext, useMemo, useState } from "react";

// API Hooks
import { fetchCollaborators } from "../collaborators/collaboratorsAPI";
import {
  getUserProfile,
  updateUser,
  updateUserImage,
} from "../user/profileAPI";

// External Libraries
import PropTypes from "prop-types";

// Store Imports
import toast from "react-hot-toast";
import { useCollaboratorsStore } from "../collaborators/collaboratorsStore";
import { useDomainStore } from "../domain/domainStore";
import {
  fetchAnnouncementNotifications,
  fetchTaskNotifications,
} from "../notifications/notificationsAPI";
import {
  useAnnouncementNotificationsStore,
  useTaskNotificationsStore,
} from "../notifications/notificationsStore";
import { useAvatarStore } from "../user/profile/avatarStore";
import { useProfileStore } from "../user/profile/profileStore";

const OnboardingContext = createContext();

export const OnboardingProvider = ({ children }) => {
  // Zustand States
  const activeDomain = useDomainStore((store) => store.activeDomain);

  const {
    setAnnouncementNotifications,
    setError: setAnnouncementNotificationsError,
  } = useAnnouncementNotificationsStore((store) => store);

  const { setTaskNotifications, setError: setTaskNotificationsError } =
    useTaskNotificationsStore((store) => store);

  const setCollaborators = useCollaboratorsStore(
    (store) => store.setCollaborators
  );

  const setCurrentAvatar = useAvatarStore((store) => store.setCurrentAvatar);

  const { userData, setUserData } = useProfileStore((store) => store);

  const { fullName } = userData || {};

  const [onboardingState, setOnboardingState] = useState(1);

  const [loading, setLoading] = useState(false);

  const [selectedImage, setSelectedImage] = useState(null);

  const [localSelectedImage, setLocalSelectedImage] = useState(null);

  const createISOTimeString = (hours, minutes) => {
    const date = new Date();
    date.setHours(hours, minutes, 0, 0);
    return date.toISOString();
  };

  const DEFAULT_FROM_TIME = createISOTimeString(9, 0); // 9 AM
  const DEFAULT_TO_TIME = createISOTimeString(17, 0); // 5 PM

  const [modifiedUser, setModifiedUser] = useState({
    fullName,
    username: "Johnny Cage",
    jobTitle: JOB_TITLES[0].name,
    department: DEPARTMENTS[0].name,
    location: COUNTRIES[0].name,
    // language: "gb",
    availableHoursFrom: DEFAULT_FROM_TIME,
    availableHoursTo: DEFAULT_TO_TIME,
  });

  const [selectedDepartment, setSelectedDepartment] = useState(DEPARTMENTS[0]);

  const [selectedJobTitle, setSelectedJobTitle] = useState(JOB_TITLES[0]);

  const [selectedLocation, setSelectedLocation] = useState(COUNTRIES[0]);

  const [defaultFromTime, setDefaultFromTime] = useState(DEFAULT_FROM_TIME);

  const [defaultToTime, setDefaultToTime] = useState(DEFAULT_TO_TIME);

  const contextValues = useMemo(() => {
    const handleChange = (key, value) => {
      if (value.length <= 50) {
        setModifiedUser((prevState) => ({
          ...prevState,
          [key]: value,
        }));
      }
    };

    const handleNext = () => setOnboardingState(onboardingState + 1);

    const handleBack = () => setOnboardingState(onboardingState - 1);

    async function handleUserUpdate() {
      setLoading(true);

      const response = await updateUser(
        {
          fullName: userData.fullName,
          username: modifiedUser.username,
          jobTitle: modifiedUser.jobTitle,
          department: modifiedUser.department,
          location: modifiedUser.location,
          // language: modifiedUser.language,
          availableHoursFrom: modifiedUser.availableHoursFrom,
          availableHoursTo: modifiedUser.availableHoursTo,
        },
        activeDomain.id
      );

      if (!response.success) {
        toast.error(response.message);
        setLoading(false);
        return;
      }

      // If an image is selected, upload it
      if (selectedImage) {
        try {
          const formData = new FormData();
          formData.append("profilePicture", selectedImage); // assuming selectedImage is a file object

          // Call the image upload endpoint
          const updateUserResponse = await updateUserImage(
            formData,
            activeDomain.id
          );

          if (!updateUserResponse.success) {
            console.error(
              "Error updating user profile:",
              updateUserResponse.message
            );
            setLoading(false);
            return;
          }

          // Update the user data with the new profile picture
          setUserData({ ...userData, profilePicture: updateUserResponse.data });
        } catch (error) {
          console.error("Image upload failed:", error);
          toast.error("Failed to upload profile picture.");
          setLoading(false);
          return;
        }
      }

      if (selectedImage !== null) setCurrentAvatar(selectedImage);

      // Use Promise.all to run both fetch operations in parallel
      const [userDataResponse, collaboratorsResponse] = await Promise.all([
        getUserProfile(),
        fetchCollaborators(activeDomain.id),
      ]);

      // User data Handler
      if (!userDataResponse.success) toast.error(userDataResponse.message);

      if (userDataResponse.success)
        setUserData(userDataResponse.data.user_data);

      // Collaborators data handlers
      if (!collaboratorsResponse.success) {
        toast.error(collaboratorsResponse.message);
        setLoading(false);
      }

      if (collaboratorsResponse.success)
        setCollaborators(collaboratorsResponse.data);

      if (userDataResponse.success && collaboratorsResponse.success) {
        setLoading(false);
        handleNext();
      }

      const [newAnnouncementNotifications, newTaskNotifications] =
        await Promise.all([
          fetchAnnouncementNotifications(activeDomain.id),
          fetchTaskNotifications(activeDomain.id),
        ]);

      if (!newAnnouncementNotifications.success)
        setAnnouncementNotificationsError(newAnnouncementNotifications.message);

      if (!newTaskNotifications.success)
        setTaskNotificationsError(newTaskNotifications.message);

      setAnnouncementNotifications(newAnnouncementNotifications.data);

      setTaskNotifications(newTaskNotifications.data);
    }

    return {
      onboardingState,
      setOnboardingState,
      loading,
      setLoading,
      modifiedUser,
      setModifiedUser,
      selectedDepartment,
      setSelectedDepartment,
      selectedJobTitle,
      setSelectedJobTitle,
      selectedLocation,
      setSelectedLocation,
      defaultFromTime,
      setDefaultFromTime,
      defaultToTime,
      setDefaultToTime,
      handleChange,
      handleNext,
      handleBack,
      handleUserUpdate,
      selectedImage,
      setSelectedImage,
      localSelectedImage,
      setLocalSelectedImage,
    };
  }, [
    onboardingState,
    loading,
    modifiedUser,
    selectedDepartment,
    selectedJobTitle,
    selectedLocation,
    defaultFromTime,
    defaultToTime,
    selectedImage,
    localSelectedImage,
    userData,
    setCurrentAvatar,
    activeDomain.id,
    setUserData,
    setCollaborators,
    setAnnouncementNotificationsError,
    setTaskNotificationsError,
    setAnnouncementNotifications,
    setTaskNotifications,
  ]);

  return (
    <OnboardingContext.Provider value={contextValues}>
      {children}
    </OnboardingContext.Provider>
  );
};

OnboardingProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Custom hook with a guard clause
export const useOnboardingContext = () => {
  const context = useContext(OnboardingContext);
  if (!context) {
    throw new Error(
      "useOnboardingContext must be used within a OnboardingContext Provider"
    );
  }
  return context;
};
