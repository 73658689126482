// External Libraries
import AnnouncementSearchResult from "@/features/announcements/AnnouncementSearchResult";
import { useAnnouncementsStore } from "@/features/announcements/announcementsStore";
import CollaboratorSearchResults from "@/features/collaborators/CollaboratorSearchResults";
import { useCollaboratorsStore } from "@/features/collaborators/collaboratorsStore";
import { usePanelStore } from "@/features/panel/panelStore";
import TaskSearchResult from "@/features/tasks/TaskSearchResult";
import { useTaskStore } from "@/features/tasks/tasksStore";
import { useClickOutside, useDebounce } from "@/hooks/hooks";
import { DEBOUNCE_DURATION } from "@/utils/constants";
import PropTypes from "prop-types";
import { useRef, useState } from "react";
import { FaXmark } from "react-icons/fa6";
import { FiSearch } from "react-icons/fi";
import { useLocation } from "react-router-dom";

export default function UtilitySearch({ setShowUtilitySearch }) {
  // Hooks and State
  // Zustand States
  const collaborators = useCollaboratorsStore((store) => store.collaborators);

  const announcements = useAnnouncementsStore((store) => store.announcements);

  const activePanel = usePanelStore((store) => store.activePanel);

  const tasks = useTaskStore((store) => store.tasks);

  // Selecting the current tasks via the panel ID
  const activePanelTasks = tasks[activePanel?.id] || [];

  const location = useLocation();
  const path = location.pathname;
  // Determine the placeholder text based on the current path
  const getPlaceholderText = () => {
    if (path.includes("/panel")) return "Search for a Task";
    if (path.includes("/collaborators")) return "Search for a Collaborator";
    if (path.includes("/announcements")) return "Search for an Announcement";
    return "Search";
  };

  // React states and refs
  const [search, setSearch] = useState("");

  const [foundResults, setFoundResults] = useState([]);

  const [searched, setSearched] = useState(false); // State to track if search has been attempted

  const formRef = useRef(null);

  // const handleSearch = (value) => {
  //   setSearched(true); // Set searched to true after attempting a search

  //   if (value.trim() === "") {
  //     setFoundResults([]);
  //     return;
  //   }

  //   if (path.includes("/collaborators")) {
  //     const filteredCollaborators = collaborators.filter((c) =>
  //       c.user.fullName.toLowerCase().includes(value.toLowerCase())
  //     );
  //     setFoundResults(filteredCollaborators);
  //   }

  //   if (path.includes("/announcements")) {
  //     const filteredNotifications = announcements.filter((c) =>
  //       c.content.toLowerCase().includes(value.toLowerCase())
  //     );
  //     setFoundResults(filteredNotifications);
  //   }
  //   if (path.includes("/panel")) {
  //     const filteredTasks = activePanelTasks.filter((c) =>
  //       c.title.toLowerCase().includes(search.toLowerCase())
  //     );
  //     setFoundResults(filteredTasks);
  //   }
  // };

  const handleSearch = (value) => {
    if (value.trim() === "") {
      setSearched(false); // Do not set searched to true if the value is empty
      setFoundResults([]);
      return;
    }

    setSearched(true); // Set searched to true only if the value is not empty

    if (path.includes("/collaborators")) {
      const filteredCollaborators = collaborators.filter((c) =>
        c.user.fullName.toLowerCase().includes(value.toLowerCase())
      );
      setFoundResults(filteredCollaborators);
    }

    if (path.includes("/announcements")) {
      const filteredNotifications = announcements.filter((c) =>
        c.content.toLowerCase().includes(value.toLowerCase())
      );
      setFoundResults(filteredNotifications);
    }
    if (path.includes("/panel")) {
      const filteredTasks = activePanelTasks.filter((c) =>
        c.title.toLowerCase().includes(value.toLowerCase())
      );
      setFoundResults(filteredTasks);
    }
  };

  const clearSearch = () => {
    setSearch("");
    setSearched(false);
    setFoundResults([]);
  };

  useClickOutside(formRef, () => clearSearch());

  const debounceDisplayResults = useDebounce(handleSearch, DEBOUNCE_DURATION);

  return (
    <div className="absolute z-[100] gap-2 flex bg-darkGray top-0 p-2 left-0 w-full items-center ">
      <form
        className="relative w-full gap-2 "
        onSubmit={(e) => e.preventDefault()}
        ref={formRef}>
        <input
          type="text"
          className="w-full hover:border-primary placeholder:text-[14px] h-full p-2 border border-lines focus:outline-none  transition-all duration-300 rounded-sm  bg-darkGray py-[9px] focus-within:outline-none focus-within:border-primary"
          placeholder={getPlaceholderText()}
          value={search}
          onChange={(e) => {
            const { value } = e.target;
            setSearch(value);
            debounceDisplayResults(value); // Debounce displaying results based on input
          }}
        />
        {search.length > 0 && (
          <button className="absolute p-2 -translate-y-1/2 rounded-full cursor-pointer right-4 top-1/2 hover:bg-primaryHover">
            <FiSearch />
          </button>
        )}
        {path.includes("/collaborators") && (
          <CollaboratorSearchResults
            searched={searched}
            foundResults={foundResults}
            clearSearch={clearSearch}
          />
        )}
        {path.includes("/announcements") && (
          <AnnouncementSearchResult
            searched={searched}
            foundResults={foundResults}
            clearSearch={clearSearch}
          />
        )}
        {path.includes("/panel") && (
          <TaskSearchResult
            searched={searched}
            foundResults={foundResults}
            clearSearch={clearSearch}
          />
        )}
      </form>
      <div
        className="p-2 rounded-full cursor-pointer hover:bg-veryDarkGray"
        onClick={() => setShowUtilitySearch(false)}>
        <FaXmark />
      </div>
    </div>
  );
}

UtilitySearch.propTypes = {
  setShowUtilitySearch: PropTypes.func.isRequired,
};
