// Component imports
import BarLoader from "@/UI/BarLoader";
import AuthLayout from "../AuthLayout";

// Store Imports

// External Libraries
import { auth, googleProvider } from "@/firebase/firebaseConfig";
import { AnimatePresence, motion } from "framer-motion";
import PropTypes from "prop-types";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import { FiCheck } from "react-icons/fi";
import { HiXMark } from "react-icons/hi2";

// React Imports
import { useAccessibleClick, usePasswordCriteria } from "@/hooks/hooks";
import { useState } from "react";

import { useAnalyticsStore } from "@/features/analytics/analyticsStore";
import { useCollaboratorsStore } from "@/features/collaborators/collaboratorsStore";
import { useColumnStore } from "@/features/column/columnStore";
import { useDomainStore } from "@/features/domain/domainStore";
import { useOnboardingStore } from "@/features/onboarding/onBoardingStore";
import { usePanelStore } from "@/features/panel/panelStore";
import { useProfileStore } from "@/features/user/profile/profileStore";
import { CRITERIA_MESSAGE } from "@/utils/constants";
import { signInWithPopup } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { googleSignUp } from "../authApi";
import { InputField } from "./SignupInput";
import { useSignup } from "./useSignup";
import GoogleIcon from "@/icons/GoogleIcon";
import { formatAndStoreAnalytics } from "@/utils/utils";
import { initializeSocket } from "@/sockets/socket";

export default function Signup() {
  const {
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    criteria,
    checkCriteria,
    showPassword,
    setShowPassword,
    showConfirmPassword,
    setShowConfirmPassword,
    criteriaVisible,
    setCriteriaVisible,
  } = usePasswordCriteria();

  const { handleSignup, loading, errorMessage, setLoading } = useSignup();

  // Zustand States
  const { setDomains, setActiveDomain } = useDomainStore((state) => state);

  const setUserData = useProfileStore((state) => state.setUserData);

  const setPanels = usePanelStore((store) => store.setPanels);

  const setColumns = useColumnStore((store) => store.setColumns);

  const setCollaborators = useCollaboratorsStore(
    (store) => store.setCollaborators
  );

  const setIsOnboarding = useOnboardingStore((store) => store.setIsOnboarding);

  const { setAnalytics, setActiveAnalyticsId } = useAnalyticsStore(
    (store) => store
  );

  const navigate = useNavigate();

  // States
  const [domainName, setDomainName] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  // const [loading, setLoading] = useState(false)

  const allCriteriaMet = () =>
    Object.values(criteria).every((value) => value === true);

  const handleUserSignup = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword || !allCriteriaMet()) return;

    setLoading(true);

    const userData = {
      email: email.toLowerCase(),
      password,
      fullName,
      domainName,
      country: "",
      phone: "",
    };

    await handleSignup(userData);
    setLoading(false);
  };

  const handleGoogleSignUp = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const userCredentials = await signInWithPopup(auth, googleProvider);
      const user = userCredentials.user;

      const accessToken = await user.getIdToken();

      const data = {
        accessToken,
      };

      const googleResult = await googleSignUp(data);

      if (!googleResult.success) {
        setLoading(false);
        return;
      }

      const { user_data, domains, access_token } = googleResult.data;
      const firstDomain = domains?.domains?.[0];

      const params = {
        userId: user_data.id,
        domainId: firstDomain.id,
      };

      initializeSocket(params);

      // Store the access token
      localStorage.setItem("kreed_at", access_token);

      // Set states with extracted data
      setUserData(user_data);
      setDomains(domains?.domains);
      setActiveDomain(firstDomain);
      setPanels(domains?.panels);
      setColumns(firstDomain?.status);
      setCollaborators(domains?.members);

      setActiveAnalyticsId(firstDomain.id);

      const { totalTimeAnalyticsFor5Days, averageTimeAnalyticsFor5Days } =
        domains.analytics;

      // Format and store totalTimeSpent under '5D'
      formatAndStoreAnalytics(
        totalTimeAnalyticsFor5Days,
        "5D", // Key for the time range
        "totalTimeSpent" // Type of analytics being stored
      );

      // Format and store averageTimeSpent under '5D'
      formatAndStoreAnalytics(
        averageTimeAnalyticsFor5Days,
        "5D", // Key for the time range
        "averageTimeSpent" // Type of analytics being stored
      );

      setAnalytics(domains.analytics.analytics);

      // Navigate to the home page
      navigate("/home");
      setLoading(false);
      setIsOnboarding(true);
    } catch (error) {
      console.error("Error during Google signup:", error.message);
    }
  };

  return (
    <AuthLayout
      title="Sign up"
      linkTo="/auth/login"
      linkText="Already have an Account? ">
      <p className="text-[14px]  mt-1 sm:text-base">
        Enter your details to register a new account
      </p>
      <button
        className="flex items-center justify-center gap-2 my-4 google__button disabled:cursor-not-allowed"
        onClick={handleGoogleSignUp}
        disabled={loading}>
        {loading ? (
          <BarLoader />
        ) : (
          <>
            <span>
              <GoogleIcon size="24" />
            </span>{" "}
            Sign up with Google
          </>
        )}
      </button>
      {/* <div className="relative flex justify-center mb-4 divider__lines">Or</div>
      <form className="flex flex-col gap-4 mt-4" onSubmit={handleUserSignup}>
        <InputField
          label="Email Address"
          type="email"
          name="email"
          placeholder="David@company.com"
          required={true}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <InputField
          label="Full Name"
          type="text"
          name="full-name"
          placeholder="David Jaja"
          required={true}
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
        />

        <InputField
          label="Domain Name"
          type="text"
          name="domain-name"
          placeholder="David's Domain"
          required={true}
          value={domainName}
          minLength={3}
          onChange={(e) => setDomainName(e.target.value)}
        />
        <div>
          <div className="">
            <label
              htmlFor="password"
              className=" mb-2 font-normal text-[14px]  block   text-textColor">
              Password
            </label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="password1234*"
                className="input__style "
                required
                onFocus={() => {
                  if (!criteriaVisible) {
                    setCriteriaVisible(true);
                    checkCriteria(password, confirmPassword);
                  }
                }}
                onChange={(e) => {
                  setPassword(e.target.value);
                  checkCriteria(e.target.value, confirmPassword);
                }}
                value={password}
              />
              <span
                className="absolute -translate-y-1/2 cursor-pointer right-4 top-1/2 "
                title={showPassword ? "hide" : "show"}
                onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          </div>
          <AnimatePresence>
            {criteriaVisible && (
              <motion.ul
                initial={{ scale: 0.8 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0 }}
                key={criteriaVisible}
                className="grid grid-cols-2 gap-2 my-2 text-sm font-regular">
                <CriteriaItem
                  met={criteria.length}
                  message={CRITERIA_MESSAGE.length}>
                  8+ characters
                </CriteriaItem>
                <CriteriaItem
                  met={criteria.number}
                  message={CRITERIA_MESSAGE.number}>
                  Number
                </CriteriaItem>
                <CriteriaItem
                  met={criteria.specialChar}
                  message={CRITERIA_MESSAGE.specialChar}>
                  Special character
                </CriteriaItem>
                <CriteriaItem
                  met={criteria.uppercase}
                  message={CRITERIA_MESSAGE.uppercase}>
                  Uppercase
                </CriteriaItem>
                <CriteriaItem
                  met={criteria.match}
                  message={CRITERIA_MESSAGE.match}>
                  Confirm Password
                </CriteriaItem>
              </motion.ul>
            )}
          </AnimatePresence>
        </div>

        <div>
          <label
            htmlFor="confirm-password"
            className="  font-normal text-[14px]  block mb-2   text-textColor">
            Confirm your Password
          </label>
          <div className="relative ">
            <input
              type={showConfirmPassword ? "text" : "password"}
              placeholder="password1234*"
              className="input__style"
              required
              autoComplete="true"
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                checkCriteria(password, e.target.value);
              }}
              value={confirmPassword}
            />
            <span
              className="absolute -translate-y-1/2 cursor-pointer right-4 top-1/2"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              onKeyDown={useAccessibleClick(() =>
                setShowConfirmPassword(!showConfirmPassword)
              )}
              title={showConfirmPassword ? "hide" : "show"}>
              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
        </div>
        {errorMessage && (
          <p className="text-xs mt-1.5 text-cautionHover">{errorMessage}</p>
        )}

        <button
          className={`form__button ${
            loading || (!allCriteriaMet() && " cursor-not-allowed")
          }`}
          disabled={loading || !allCriteriaMet()}>
          {loading ? <BarLoader /> : `Sign Up`}
        </button>
      </form> */}
    </AuthLayout>
  );
}

const Tooltip = ({ content, children, met }) => {
  return (
    <div className="relative flex items-center group">
      {children}
      <div
        className={`absolute text-center  z-10 hidden w-full px-2 p-2 mb-2 text-xs text-white rounded   bottom-full group-hover:block ${
          met ? " bg-activeUser" : " bg-veryDarkGray"
        }`}>
        <span className="">{content}</span>
        <div
          className={`absolute bottom-0 w-4 rotate-45 -translate-x-1/2 translate-y-1/4 -z-10  left-1/2 aspect-square ${
            met ? " bg-activeUser" : " bg-veryDarkGray"
          }`}></div>
      </div>
    </div>
  );
};

const CriteriaItem = ({ met, message, children }) => (
  <Tooltip content={met ? message.met : message.unmet} met={met}>
    <li
      className={` w-full justify-center  ${
        met ? " bg-primary  text-white" : " text-caution"
      } border border-lines px-2 py-1.5 rounded-full flex gap-2 items-center`}>
      <span>{children}</span>
      <span className="translate-y-[1px] ">
        {met ? <FiCheck /> : <HiXMark />}
      </span>
    </li>
  </Tooltip>
);

CriteriaItem.propTypes = {
  met: PropTypes.bool,
  message: PropTypes.objectOf(String),
  children: PropTypes.string,
};
Tooltip.propTypes = {
  content: PropTypes.string,
  met: PropTypes.bool,
  children: PropTypes.node,
};
