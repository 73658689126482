import { apiRequestWithTimeout, handleApiError } from "@/utils/apiUtils";

// import { deleteToken, getToken } from "firebase/messaging";

// import {
//   // userToken as currentUserToken,
//   messaging,
// } from "@/firebase/firebaseConfig";
import { OAUTH_TIMEOUT_DURATION } from "@/utils/constants";

export async function googleSignUp(data) {
  const { accessToken } = data;

  const config = {
    method: "post",
    url: `/auth/google/signup`,
    data: {
      accessToken: accessToken,
    },
  };

  const { data: responseData, error } = await apiRequestWithTimeout(
    config,
    OAUTH_TIMEOUT_DURATION
  );

  if (error) return handleApiError(error);

  return { success: true, data: responseData };
}
export async function googleSignIn(accessToken) {
  const config = {
    method: "post",
    url: `/auth/google/login`,
    data: {
      accessToken: accessToken,
    },
  };

  const { data: responseData, error } = await apiRequestWithTimeout(
    config,
    OAUTH_TIMEOUT_DURATION
  );

  if (error) return handleApiError(error);

  return { success: true, data: responseData };
}
