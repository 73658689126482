import axios from "axios";
import { setSessionExpired } from "@/UI/sessionsStore";
import { TIMEOUT_DURATION } from "@/utils/constants";
import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

// Utility function to merge class names
export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

// Function to get the token from local storage
export const getToken = () => {
  if (typeof window !== "undefined") {
    return localStorage.getItem("kreed_at");
  }
  return "";
};

// Create an axios instance with default configuration
const api = axios.create({
  baseURL: import.meta.env.VITE_API_SERVER,
  // Removed default Content-Type header here
});

// Request interceptor to add authorization token and set Content-Type
api.interceptors.request.use(
  (config) => {
    if (typeof window !== "undefined") {
      const token = getToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    // Check if the data is a FormData instance to set 'multipart/form-data'
    if (config.data instanceof FormData)
      config.headers["Content-Type"] = "multipart/form-data";
    else config.headers["Content-Type"] = "application/json";

    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor to handle specific status codes
api.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log("The error", error);
    if (error.response?.status === 401) {
      if (typeof window !== "undefined") {
        setSessionExpired(true);
      }
    } else if (error.request) {
      if (typeof window !== "undefined" && !navigator.onLine) {
        console.log("Root", error);
      }
    }

    return Promise.reject(error);
  }
);

// Utility function for making API requests with a timeout
const apiRequestWithTimeout = async (config, timeout = TIMEOUT_DURATION) => {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), timeout);

  try {
    const response = await api({
      ...config,
      signal: controller.signal,
    });

    clearTimeout(timeoutId);

    return { data: response.data, error: null };
  } catch (error) {
    clearTimeout(timeoutId);
    return { data: null, error };
  }
};

function handleApiError(error) {
  let errorMessage = "An error occurred while processing your request.";

  if (error.name === "CanceledError")
    errorMessage = "Request timed out due to slow internet connection.";

  if (error.message === "Network Error")
    errorMessage = "No network connection. Please check your internet.";

  if (error.response) errorMessage = error.response.data.message;

  return {
    success: false,
    message: errorMessage,
    statusCode: error.response?.status,
  };
}

export { apiRequestWithTimeout, handleApiError };
